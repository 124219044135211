import React from "react"
import { FaRegEnvelope, FaICursor, FaRegUser} from 'react-icons/fa'

import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

import Layout from "../components/Layout";
import Seo from "../components/Seo"
import Title from "../components/Title"
import LoadingCKEditor from "../components/LoadingCKEditor"

const contact = () => {
  const TranslatorComponent = (text) => {
    const {t} = useI18next();
    return t(text);
  }

  const submitHandler = (event) => {
    const ckeditorHidden = document.querySelector("#ckeditorHidden");
    if (!ckeditorHidden.innerHTML) {
      event.preventDefault();
      alert('formulario incompleto')
    }
  }

  return (
    <Layout>
      <Seo title="Contáctame" />
      <main className="contact-page">
        <section className="contact">
          <Title title="Ponerse en contacto conmigo" />
          <div className="contact-img">
            <StaticImage src="../assets/images/contact.svg" alt="Contáctame img" layout="fixed" height={300} placeholder="blurred" />
          </div>
          <article className="contact-form">
            <form id="contactForm" onSubmit={submitHandler} name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
              {/* <form action="https://getform.io/f/dd1f93e7-4fd8-43ec-b4f1-50c433a74699" method="POST" data-netlify="true"> */}
              <div style={{display: 'none'}}>
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
              </div>
              <div className="form-group">
                <label htmlFor="personName" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.175rem'}}>
                  <FaRegUser height={32} />&nbsp;
                  <Trans>Nombre</Trans>
                </label>
                <input type="text" id="personName" name="name" placeholder={TranslatorComponent("ingresar nombre")} className="form-control" required />
              </div>
              <div className="form-group">
                <label htmlFor="personEmail" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.175rem'}}>
                  <FaRegEnvelope height={32} />&nbsp;
                  <Trans>Correo electrónico</Trans>
                </label>
                <input type="email" id="personEmail" name="email" placeholder={TranslatorComponent("ingresar correo electrónico")} className="form-control" required />
              </div>
              <div className="form-group">
                <label htmlFor="ckeditorHidden" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.175rem'}}>
                  <FaICursor height={32} />&nbsp;
                  <Trans>Mensaje</Trans>
                </label>
                <textarea id="ckeditorHidden" name="message" rows="10" placeholder={TranslatorComponent("ingresar mensaje")} className="form-control" style={{display: 'none'}}></textarea>
              </div>
              <div className="form-group">
                <LoadingCKEditor />
              </div>
              <div className="form-group">
                <button type="submit" className="btn submit-btn">
                <span role="img" aria-label="click" aria-labelledby="click">🤝</span>
                  &nbsp;<Trans>Enviar</Trans>
                </button>
              </div>
            </form>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default contact

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
