import React, { useState } from "react"
import Loadable from "@loadable/component"

import Skeleton from 'react-loading-skeleton';

const LoadableEditor = Loadable(() => import("../components/LoadableEditor"))

const LoadingCKEditor = () => {
  let [isCKEditorReady, setCKEditorState] = useState(false);
	
	return (
		<>
			<Skeleton count={1} height={240} style={{ display: isCKEditorReady ?  'none' : '' }} />
			<LoadableEditor setCKEditorState={setCKEditorState} />
		</>
	)
}

export default LoadingCKEditor